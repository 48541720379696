import React, {useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom'

import {useToast} from 'helpers/hooks';
import {request} from 'helpers/tools';
import {Button} from 'components/Button';

import {useModal} from "../helpers/hooks";
import {ForwardPickerModal} from "./ForwardPickerModal";
import {ForwardTreePickerModal} from "./ForwardTreePickerModal";
import {DuplicateModal} from "components/DuplicateModal";
export const ActionButton = ({canEdit, className, data, forward, setForwarding, submitForm, update, validateForm, setDuplicates, duplicates, user,saveFunction, miscellaneousDocType,detaildoc}) => {
    
    let ignoreDuplicates = false
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [forwardpickermodal, toggleforwardpickerModal] = useModal();
    const [forwardtreepickermodal, toggleforwardtreepickerModal] = useModal();
    const [duplicateModal, toggleDuplicateModal] = useModal();

    let [returnedDuplicates, setReturnedDuplicates] = useState([]);
    

    if (isSubmitting){}

    const history = useHistory();
    const location = useLocation();
    const showToast = useToast();

    let [buttonTitle, setButtonTitle] = useState('');
    let [busy,setBusy] = useState(false);

    const text = forward ? 'Odeslat' : 'Přiřadit';
    
    if (buttonTitle===''){
        setButtonTitle(text)
    }
    

    let docsInfos = []
    
    if ( typeof data === 'string' ){
        docsInfos.push(detaildoc.document_info)
    }
    else{
        data.forEach( d => docsInfos.push(d.document_info))
    }
    
    const docs = typeof data === 'string' ? [data] : data.map(doc => doc.uuid);
    
    const usersToggle = user.toggle_selection_of_management_users
    const approvalTreeToggle = user.toggle_selection_of_approval_tree
    
    const handleAction = async () => {
        const listPage = ['/documents', '/my_documents'].includes(location.pathname);
        const body = typeof data === 'string' ? [data] : data.map(doc => doc.uuid);

        const redirect = () => {
            setButtonTitle(text)
            setBusy(false)
            if (forward){history.push('/my_documents');}
            else{history.push(location.pathname.replace('documents', 'my_documents'));}
            window.location.reload();
        }

        if (listPage) { // documents sent from list
            if (!data.length) {
                setButtonTitle(text)
                setBusy(false)
                return showToast('Nebyl označen žádný doklad.');
            } else if (data.some(doc => !doc.client_company)) { // missing client company
                setButtonTitle(text)
                setBusy(false)
                return showToast('Všechny doklady musejí mít vyplňenou klientskou společnost');
            }
        } 
        let url = forward ? '/my_documents/forward/' : '/documents/assign/?assign_to_user='+user["pk"]+"&user_role="+user["role"].toLowerCase()
        if (ignoreDuplicates===true){
            url = forward ? '/my_documents/forward/?force=true' : '/documents/assign/?assign_to_user='+user["pk"]+"&user_role="+user["role"].toLowerCase()
        }
        
        const response = await request(url, 'POST', body);
        setIsSubmitting(false);

        if (ignoreDuplicates===false){
            let duplicitni = response.body?.filter(dok => dok.duplicates.length > 0);
            if (duplicitni && duplicitni.length){
                //Show modal
                duplicitni.forEach((dok, i) => {
                    if (Array.isArray(data)) {
                        let matchingDoc = data.find(datdok => datdok.uuid === dok.document);
                        if (matchingDoc) {
                            duplicitni[i].name = matchingDoc.name;
                        }
                    }
                    else{
                        duplicitni[i].name=data
                    }
                });
            
                setDuplicates(duplicitni);
                setButtonTitle(text)
                setBusy(false)
                return;
            }
        }
        if (forward) {
            if (body.length === 1 && response.body[0].missing_fields.length) {
                setButtonTitle(text)
                setBusy(false)
                return showToast('Vyplňte povinná pole:', response.body[0].missing_fields)
            }
            if (response.body.some(field => field.missing_fields.length)) {
                const submitted = response.body.filter(field => !field.missing_fields.length).length;
                showToast(
                    `Doklady s chybějícími povinnými poli nebyly odeslány. Odesláno ${submitted}/${body.length} dokladů.`
                )
                setButtonTitle(text)
                setBusy(false)
                return update();
            }
            showToast('Odesláno');
           
            console.log('reloading after successfull forward')
            return redirect();
        }
        console.log('reloading after successfull submit')
        return redirect();
    };
 

    const handleMissingFieldsInfo = (docs, response) =>{
        console.log("Missing fields check")
        console.log(response)
        if (docs.length === 1 && response.body && response.body[0].missing_fields.length) {
            console.log("Problems with fields for docs:")
            console.log(docs)
            console.log(response.body)
            setButtonTitle(text)
            setBusy(false)
            showToast('Vyplňte povinná pole:', response.body[0].missing_fields)
            return true
        }
        if (response.body &&response.body.some(field => field.missing_fields.length)) {
            const submitted = response.body.filter(field => !field.missing_fields.length).length;
            console.log("Problems with fields for docs:")
            console.log(docs)
            console.log(response.body)
            setButtonTitle(text)
            setBusy(false)
            showToast(`Doklady s chybějícími povinnými poli nebyly odeslány. Odesláno ${submitted}/${docs.length} dokladů.`)
            return true
        }
        return false
    }


    const handleModalAction = async (users, docsToApproval, docsWithoutApproval,usertype) => {
        //const listPage = ['/documents', '/my_documents'].includes(location.pathname);
        let docsToApproval_noTree = []
        let docsToApproval_withTree_to_manager = []
        let docsToApproval_withTree_to_accountant = []
        
        if (usertype==="direct"){
            docsToApproval[0].forEach(d=>{
                docsToApproval_withTree_to_accountant.push(d)
            })
        }
        else{
            docsToApproval[0].forEach(d=>{
                docsToApproval_noTree.push(d)
            })
        }
        
        
        docsToApproval[1].forEach(d=>{
            if (d["next_role"]==="managementuser"){
                console.log(d)
                docsToApproval_withTree_to_manager.push(d["document"])
            }
            else if (d["next_role"]==="accountantuser"){
                docsToApproval_withTree_to_accountant.push(d["document"])
            }
          })


        console.log('handling modal action')
        console.log(docsToApproval)
        
        console.log('docs without tree')
        console.log(docsToApproval_noTree)
        
        console.log('docsToApproval_withTree_to_manager')
        console.log(docsToApproval_withTree_to_manager)

        console.log('docsToApproval_withTree_to_accountant')
        console.log(docsToApproval_withTree_to_accountant)
        
        const redirect = () => {
            setButtonTitle(text)
            setBusy(false)
            history.push('/my_documents');
            window.location.reload();
            
        }
        //sessionStorage.setItem("stateforduplicatemodal", "forcesave");
        
        const baseurl = forward ? '/my_documents/forward/' : '/documents/assign/';
        
        if (usertype ==="assign_to_myself"){
            let assignedUserId = parseInt(user["pk"])
            url = baseurl+"?assign_to_user="+assignedUserId+"&user_role="+user["role"].toLowerCase()
            let response = await request(url, 'POST', docs);
            if (response){
                let shouldStop  = handleMissingFieldsInfo(docs, response)
                if (shouldStop === true){
                    return terminate()
                }
            }
        }

        /* Documents without approval -> direct forward */
        if (docsWithoutApproval.length>0){
            let url = baseurl+"?force=true"
            const response = await request(url, 'POST', docsWithoutApproval);
            if (response){
                let shouldStop  = handleMissingFieldsInfo(docsWithoutApproval, response)
                if (shouldStop === true){
                    return terminate()
                }
            }
        }
        
        /* Documents to approval with tree -> direct forward to manager */
        if (docsToApproval_withTree_to_manager.length>0){
            let url = "/my_documents/forward/?force=true"
            let response = await request(url, 'POST', docsToApproval_withTree_to_manager);
            if (response){
                let shouldStop  = handleMissingFieldsInfo(docsToApproval_withTree_to_manager, response)
                if (shouldStop === true){
                    return terminate()
                }
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
        }

        /* Documents to approval with tree and nowhere to go -> direct forward to accountant */
        if (docsToApproval_withTree_to_accountant.length>0){
            /*
            docsToApproval_withTree.forEach(function(d){
                treeRequestWrapper(d)
            });
            */
            let url = "/my_documents/forward/?force=true&go_to_accounting_user=true"
            let response = await request(url, 'POST', docsToApproval_withTree_to_accountant);
            if (response){
                handleMissingFieldsInfo(docsToApproval_withTree_to_accountant, response)
            }
            await new Promise(resolve => setTimeout(resolve, 1000));

        }
        
        /* Documents to approval */
        if (docsToApproval_noTree.length>0){
            let payload = docsToApproval_noTree

            if (usertype ==="top_manager"){
                let url = baseurl+"?force=true"
                let response = await request(url, 'POST', payload);
                if (response){
                    let shouldStop  = handleMissingFieldsInfo(payload, response)
                    if (shouldStop === true){
                        return terminate()
                    }
                }
            }
            if (usertype === 'maintenance_users'){
                let url = baseurl+"?force=true"
                let response = await request(url, 'POST', payload);
                if (response){
                    let shouldStop  = handleMissingFieldsInfo(payload, response)
                    if (shouldStop === true){
                        return terminate()
                    }
                }

                let assignedUserId = parseInt(users[0]["maintenance_user_id"])
                if (assignedUserId>=0){
                    url =  "/documents/assign/?assign_to_user="+assignedUserId+"&user_role=maintenanceuser&force=true"
                    response = await request(url, 'POST', docsToApproval_noTree);
                }
                if (response){
                    let shouldStop  = handleMissingFieldsInfo(payload, response)
                    if (shouldStop === true){
                        return terminate()
                    }
                }
            }
            if (usertype === 'management_users'){
                let level = parseInt(users[0]["level"])
                let  url = baseurl+"?force=true&move_to_level="
                let response = await request(url+""+level, 'POST', payload);
                if (response){
                    let shouldStop  = handleMissingFieldsInfo(payload, response)
                    if (shouldStop === true){
                        return terminate()
                    }
                }

                let assignedUserId = parseInt(users[0]["management_user_id"])
                let assignedUserLevel = parseInt(users[0]["level"])
                if (assignedUserId>=0){
                    url = `/documents/assign/?assign_to_user=${assignedUserId}&user_role=managementuser&management_level=${assignedUserLevel}&force=true`;

                    response = await request(url, 'POST', docsToApproval_noTree);
                }
                if (response){
                    let shouldStop  = handleMissingFieldsInfo(docsToApproval_noTree, response)
                    if (shouldStop === true){
                        return terminate()
                    }
                }
            }
        }
        
        let toastTitle = "Doklad odeslán"
        if (!forward){
            toastTitle = "Doklad přiřazen"
        }

        if (users==="direct" && docsToApproval_withTree_to_manager.length===0){
            showToast('Doklady odeslány účetnímu uživateli');
        }
        else if (users==="direct" && docsToApproval_withTree_to_manager.length>0){
            showToast('Doklady odeslány účetnímu uživateli / přiřazenému schvalovateli');
        }
        else if (users[0]["name"]!==""){
            showToast(toastTitle +": " +users[0]["name"]);
        }
        else{
            showToast(toastTitle+".");
        }
        
        await new Promise(resolve => setTimeout(resolve, 1000));
        return redirect();
    };


    const terminate = () => {
        setButtonTitle(text)
        setBusy(false)
        
    }
    const handleModalActionTree = async (users, docsToApproval, docsWithoutApproval,usertype) => {
        console.log('handling tree modal')

        //const listPage = ['/documents', '/my_documents'].includes(location.pathname);
        let docsToApproval_noTree = docsToApproval[0]
        let docsToApproval_withTree =  docsToApproval[1]
        
        users.forEach(u=> console.log(u))

        const redirect = () => {
            setButtonTitle(text)
            setBusy(false)
            history.push('/my_documents');
            window.location.reload();
            
        }
      
        console.log('docsToApproval_noTree')
        console.log(docsToApproval_noTree)
        console.log('docsToApproval_withTree')
        console.log(docsToApproval_withTree)
        console.log('usertype ' + usertype)
        const baseurl = forward ? '/my_documents/forward/' : '/documents/assign/';

        /* Documents without approval -> direct forward */
        if (docsWithoutApproval.length>0){
            let url = baseurl+"?force=true"
            const response = await request(url, 'POST', docsWithoutApproval);
            if (response){
                let shouldStop  = handleMissingFieldsInfo(docsWithoutApproval, response)
                if (shouldStop === true){
                    return terminate()
                }
            }
        }
        
        /* Documents to approval */
        if (docsToApproval_withTree.length>0){
            let t  = 'There are docs to set tree already with tree. Should not get here from tree modal.'
            console.log(t)
            console.log(docsToApproval_noTree)
            showToast(t);
        }
        if (docsToApproval_noTree.length>0){
            console.log("forwarding docs with no tree with approval needed")
            let payload = {}
            payload["documents"] = []
            docsToApproval_noTree.forEach(d => {
                let dp = {}
                dp["uuid"] = d
                let id1 = users[0]["management_user_id"]
                if (id1===-100){id1 = -1}
                else if (id1 ===-1){id1 = 0}

                let id2 = null
                let id3 = null
                let id4 = null
                let id5 = null


                if (users[1] && users[1]["management_user_id"] !==null && users[1]["management_user_id"]!==undefined){
                    id2 = users[1]["management_user_id"]
                }
                if (users[2] && users[2]["management_user_id"] !==null && users[2]["management_user_id"]!==undefined){
                    id3 = users[2]["management_user_id"]
                }
                if (users[3] && users[3]["management_user_id"] !==null && users[3]["management_user_id"]!==undefined){
                    id4 = users[3]["management_user_id"]
                }
                if (users[4] && users[4]["management_user_id"] !==null && users[4]["management_user_id"]!==undefined){
                    id5 = users[4]["management_user_id"]
                }
                 
                if (id2===-200){id2 = -2}
                else if (id2 === -2){id2 = 0}

                if (id3===-300){id3 = -3}
                else if (id3 === -3){id3 = 0}

                if (id4===-400){id4 = -4}
                else if (id4 === -4){id4 = 0}

                if (id5===-500){id5 = -5}
                else if (id5 === -5){id5 = 0}

                dp["management_user_at_level_one"] = id1
                dp["management_user_at_level_two"] = id2
                dp["management_user_at_level_three"] = id3
                dp["management_user_at_level_four"] = id4
                dp["management_user_at_level_five"] = id5
                payload["documents"].push(dp)
                console.log("Pushing to payload")
                console.log(dp)
            })

            if (usertype ==="top_manager"){
                console.log("Top manager sets tree. Should not get here")
                showToast('Top manager sets tree. Should not get here');
            }
            if (usertype === 'maintenance_users'){
                console.log('There are maintenance users sets the tree. Should not get here.')
                showToast('There are maintenance users sets the tree. Should not get here.');
            }
            if (usertype === 'management_users'){
                let url = '/set_approval_tree/'
                let response = await request(url, 'POST', payload);
                console.log(payload)
                if (response.ok===true){
                    console.log(response)
                    url = baseurl+"?force=true"
                    response = await request(url, 'POST', docsToApproval_noTree);
                }
                else{
                    showToast("Error in approval tree setting. See console.")
                    console.log(response)
                }
            }
        }
        //request('/set_user_type_for_documents/', 'POST', {"documents":String(uuid),"user_type": customType});
        let toastTitle = "Doklad odeslán"
        if (!forward){
            toastTitle = "Doklad přiřazen"
        }
        if (users[0]["name"]!=="" && users[0]["name"]!=="Přeskočit"){
            showToast(toastTitle +": " +users[0]["name"]);
        }
        else{
            showToast(toastTitle+".");
        }
        
        await new Promise(resolve => setTimeout(resolve, 1000));
        return redirect();
    };


    let url = ''
  
    const toggleForwardModal = async () => {
        if (user.role==="ClientUser"  && user.uses_maintenance===true && (usersToggle===true||approvalTreeToggle===true)){
            toggleforwardpickerModal();
        }
        else if (approvalTreeToggle===true && (user.role==="ClientUser" || user.role==='MaintenanceUser')){
            toggleforwardtreepickerModal()
        }
        else if (usersToggle===true){
            toggleforwardpickerModal();
        }
        else{
            showToast('Error, should not get here.')
        }
        
    }
    
    const modalClicked = async (data, docsToApproval, docsWithoutApproval,usertype) => {
        /*if ( !['/documents', '/my_documents'].includes(location.pathname)){
            sessionStorage.setItem('reloadaftersave','false')
            saveFunction()
            sessionStorage.setItem('reloadaftersave','')
        };*/
       

        if (user.role==="ClientUser"  &&user.uses_maintenance===true && (usersToggle===true||approvalTreeToggle===true)){
            handleModalAction(data, docsToApproval, docsWithoutApproval,usertype)
        }
        if (usersToggle===true &&
            approvalTreeToggle===false){
            handleModalAction(data, docsToApproval, docsWithoutApproval,usertype)
        }
        else if (approvalTreeToggle===true){
            handleModalActionTree(data, docsToApproval, docsWithoutApproval,usertype)
        }

        toggleForwardModal();
    }

    const modalClickedDirectForward = async (docsToApproval, docsWithoutApproval,usertype) => {
        console.log("Direct forward")
        /*if ( !['/documents', '/my_documents'].includes(location.pathname)){
            sessionStorage.setItem('reloadaftersave','false')
            saveFunction()
            sessionStorage.setItem('reloadaftersave','')
        };*/

        handleModalAction("direct", docsToApproval, docsWithoutApproval,usertype)

    }

    const modalClose = () => {
        toggleForwardModal();
        setButtonTitle(text)
        setBusy(false)
    }

    const duplicatesClickedClose = () =>{
        toggleDuplicateModal()
        setButtonTitle(text)
        setBusy(false)
    }

    const duplicatesModalClickedOk =async () =>{
        ignoreDuplicates = true
        await new Promise(resolve => setTimeout(resolve, 100));
        toggleDuplicateModal()
        /*if ( !['/documents', '/my_documents'].includes(location.pathname)){
            sessionStorage.setItem('reloadaftersave','false')
            saveFunction()
            sessionStorage.setItem('reloadaftersave','')
        };*/
        toggleForwardingProcess()
    }
    

    const directForceForward = async () =>{
        let payload = docs
        const baseurl = forward ? '/my_documents/forward/' : '/documents/assign/';
        let url = baseurl+"?force=true"
        let response = await request(url, 'POST', payload);
        if (response){
            showToast('Odesláno')
        }
        const redirect = () => {
            history.push('/my_documents');
            window.location.reload();
        }
        return redirect()
    }

    const toggleForwardingProcess = async () => {
        sessionStorage.setItem("checkerrors","true")
        console.log("Action button clicked")
        //try {await validateForm();} catch (error) {}

        setButtonTitle('Odesílám...')
        setBusy(true)
        
        const listPage = ['/documents', '/my_documents'].includes(location.pathname);
        
        const users_t = Boolean(usersToggle) ?? false;
        const tree_t = Boolean(approvalTreeToggle) ?? false;
        
        if (listPage){
            if (!data.length) {
                setButtonTitle(text)
                setBusy(false)
                return showToast('Nebyl označen žádný doklad.');
                
            } else if (data.some(doc => !doc.client_company)) { 
                setButtonTitle(text)
                setBusy(false)
                return showToast('Všechny doklady musejí mít vyplňenou klientskou společnost');
            }
        }
        else if (forward){ 
           console.log(ignoreDuplicates)
           await submitForm()
           sessionStorage.setItem("actionSave","true") 
           await saveFunction(); 
           
           if (!sessionStorage.getItem("actionSave")){
            setButtonTitle(text)
            setBusy(false)
            return
           }

           if (!JSON.parse(sessionStorage.getItem("actionSave")).info.client_company){
                showToast("Vyplňte klientskou společnost.")
                setButtonTitle(text)
                setBusy(false)
            return
           }
           
           let appendix=""
           if (ignoreDuplicates===true){
                appendix= "?force=true"
           }
           let response = await fetch(`${process.env.REACT_APP_API}/detail/${sessionStorage.getItem("actionSaveUuid")}/${appendix}`, {
                method: "PUT",
                headers: {'Authorization': 'JWT ' + localStorage.getItem('DocspointJWT'), 'Content-Type': 'application/json'},
                body: sessionStorage.getItem("actionSave")
           });
           let responseBody = await response.json()
            
           console.log(responseBody)

           if (response.status === 403){
                setReturnedDuplicates(responseBody)
                toggleDuplicateModal()
                return
            }
        }
        
        /*
        if (sessionStorage.getItem("docerrors")==="errors"){
            sessionStorage.setItem("docerrors", "");
            setBusy(false)
            setButtonTitle(text)
           // return
        }*/

        //miscellaneous doc type - direct forward to archive
        if (miscellaneousDocType===true){
            directForceForward()
            setButtonTitle(text)
            setBusy(false)
            return;
        }        
        
        //Old forwarding
        if (!forward || (users_t===false && tree_t===false) || user.role==="AccountantUser" ){
            handleAction()
            ignoreDuplicates=false
        }
        //Forwarding select process
        else if (users_t===true || tree_t===true ){ 
            if (ignoreDuplicates===false){
                let response = await fetch(`${process.env.REACT_APP_API}/get_duplicates/?documents=`+[...docs], {
                    method: "GET",
                    headers: {'Authorization': 'JWT ' + localStorage.getItem('DocspointJWT'), 'Content-Type': 'application/json'},
                });
                let duplicitni = await response.json()
                
                let mappedDuplicates = duplicitni.flatMap(doc => doc.duplicates)
                if (mappedDuplicates.length>0){
                    console.log(mappedDuplicates)
                    setReturnedDuplicates(mappedDuplicates)
                    toggleDuplicateModal()
                    return
                }
            }

            toggleForwardModal()
            ignoreDuplicates=false
        }
    }
   
       
    return (
        <>
        {duplicateModal &&<DuplicateModal duplicates={returnedDuplicates} clickedClose={duplicatesClickedClose} clickedOk={duplicatesModalClickedOk} />}
        {forwardpickermodal && <ForwardPickerModal forward={forward} clickedDirectForward={modalClickedDirectForward} close={modalClose} body={docs} user={user} clicked={modalClicked} miscellaneousDocType={miscellaneousDocType} directForceForward={directForceForward} docsInfos={docsInfos} />}
        {forwardtreepickermodal && <ForwardTreePickerModal forward={forward} clickedDirectForward={modalClickedDirectForward} close={modalClose} body={docs} user={user} clicked={modalClicked} miscellaneousDocType={miscellaneousDocType} directForceForward={directForceForward} docsInfos={docsInfos}/>}
        {<Button className={className} primary disabled={busy} onClick={toggleForwardingProcess}>{buttonTitle}</Button>}
        </>
    )
};

