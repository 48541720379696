import React, {useEffect, useState} from 'react';

import {HistoryDetail} from 'components/HistoryDetail';
import {ReactComponent as ArrowDownIcon} from 'assets/svg/arrow_down.svg';
import {ReactComponent as ArrowUpIcon} from 'assets/svg/arrow_up.svg';
import {ReactComponent as CancelIcon} from 'assets/svg/cancel.svg';
import {ReactComponent as CaptureIcon} from 'assets/svg/cog.svg';
import {ReactComponent as CheckIcon} from 'assets/svg/check.svg';
import {ReactComponent as EditIcon} from 'assets/svg/edit.svg';
import {ReactComponent as MinusIcon} from 'assets/svg/minus.svg';
import {ReactComponent as PlusIcon} from 'assets/svg/plus.svg';
import {ReactComponent as UploadIcon} from 'assets/svg/upload.svg';
import {ReactComponent as ArchiveIcon} from 'assets/svg/archive.svg';
import {formatDate} from 'helpers/tools';
import styles from 'css_modules/HistoryTableRow.module.css';

export const HistoryTableRow = ({log, highlight}) => {
    const [detail, setDetail] = useState(false);
    console.log(log)
    let author = log.role ? `${log.created_by} (${log.role})` : log.created_by;
    if (log.level && 
        ((log.role==="Management user") || log.role==="Schvalovací uživatel") )
    {
        author = `${log.created_by} (${log.role+' Úr.' + log.event.management_level+''})` 
    }
    
    
    const toggleDetail = () => log.data && setDetail(!detail);
    const getActionIcon = () => {
        switch (log.event.id) {
            case 'cha':
            case 'chn':
            case 'chacpt':
            case 'chaitm' :
                return <EditIcon className={styles.defaultIcon}/>;
            case 'crtitm' :
                return <EditIcon className={styles.defaultIcon}/>;
            case 'remitm':
                return <EditIcon className={styles.removedIcon}/>;
            case 'tts':
            case 'ttsaat':
            case 'cst':
            case 'cstcpt':
                return <EditIcon className={styles.defaultIcon}/>;
            case 'upl':
            case 'aat':
                return <UploadIcon className={styles.defaultIcon}/>;
            case 'rej':
            case 'err':
                return <ArrowDownIcon className={styles.warningIcon}/>;
            case 'cpterr':
                return <CaptureIcon className={styles.defaultIcon}/>;
            case 'uns':
                return <ArrowDownIcon className={styles.defaultIcon}/>;
            case 'imp':
                return <CheckIcon className={styles.importedIcon}/>;
            case 'isdoc':
            case 'isdcst':
            case 'lftempl':
                return <UploadIcon className={styles.defaultIcon}/>;
            case 'tpl':
                return <CaptureIcon className={styles.defaultIcon}/>;
            case 'chaut':
                return <EditIcon className={styles.defaultIcon}/>;
            case 'cpt':
                return <CaptureIcon className={styles.defaultIcon}/>;
            case 'rem':
                return <CancelIcon className={styles.removedIcon}/>;
            case 'dat':
                return <CancelIcon className={styles.defaultIcon}/>;
            case 'arc':
                return <ArchiveIcon className={styles.defaultIcon}/>;
            default:
                return <ArrowUpIcon className={styles.defaultIcon}/>
        }
    };
    const getRowClassName = () => {
        if (log.data && highlight) {
            return styles.expandableHighlightedRow
        }
        if (log.data) {
            return styles.expandableRow
        }
        if (highlight) {
            return styles.highlightedRow
        }
        return null
    };

    useEffect(() => {
        if (highlight) {
            setDetail(true)
        }
    }, [highlight]);
    return (
        <>
            <tr onClick={toggleDetail} className={getRowClassName()}>
                <td className={styles.iconCell}>{getActionIcon()}</td>
                <td>{log.event.message}</td>
                <td className={styles.greyText}>{formatDate(log.created_at)}</td>
                <td className={styles.greyText}>{author}</td>
                <td className={styles.iconCell}>
                    {log.data && (detail ?
                        <MinusIcon className={styles.toggleDetailIcon}/> :
                        <PlusIcon className={styles.toggleDetailIcon}/>)
                    }
                </td>
            </tr>
            {!!log.data && detail && <HistoryDetail log={log}/>}
        </>
    )
};